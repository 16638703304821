
import { defineComponent, PropType } from 'vue';
import PdfPage from '@/components/analytics/pdf/PdfPage.vue';
import PdfAbstractRenderer from '@/components/analytics/pdf/renderers/PdfAbstractRenderer.vue';
import {
  V1EntitiesAnalyticsSurveysPagesPageGroup,
  V1EntitiesAnalyticsSurveysPagesPageQuestion,
} from '@/services/api/tsxass';
import { getElementsOuterHeights } from '@/helpers/getElementOuterHeight';

interface GroupsListItem {
  group?: V1EntitiesAnalyticsSurveysPagesPageGroup,
  questions: V1EntitiesAnalyticsSurveysPagesPageQuestion[],
  startNumber?: number,
}

// Отступ снизу таблицы (px)
const TABLE_MB = 12;

export default defineComponent({
  name: 'PdfGeneralQuestionsRenderer',

  components: {
    PdfPage,
  },

  extends: PdfAbstractRenderer,

  props: {
    surveyeeName: {
      type: String,
      default: '',
    },
    pageNumber: {
      type: Number,
      default: 0,
    },
    groups: {
      type: Array as PropType<V1EntitiesAnalyticsSurveysPagesPageGroup[]>,
      default: () => ([]),
    },
    questions: {
      type: Array as PropType<V1EntitiesAnalyticsSurveysPagesPageQuestion[]>,
      default: () => ([]),
    },
  },

  data() {
    return {
      // Высота зоны контента страницы
      pageContentHeight: 0,
      // Значения высот элементов на странице
      elementsHeight: {} as Record<string, number>,
    };
  },

  computed: {
    // Вопросы, распределенные по группам
    groupsList(): GroupsListItem[] {
      const groupsMap: Record<string, GroupsListItem> = Object.fromEntries(
        this.groups.map((group) => ([group.id, { group, questions: [] }])),
      );

      this.questions.filter((question) => question.groupId).forEach((question) => {
        groupsMap[question.groupId].questions.push(question);
      });

      const questionsWithoutGroup = this.questions.filter((question) => !question.groupId);

      return [{ questions: questionsWithoutGroup }, ...Object.values(groupsMap)]
        .filter((item) => item.questions.length);
    },
    // Группы вопросов, разделенные на страницы
    pages(): GroupsListItem[][] {
      // eslint-disable-next-line arrow-body-style
      const getGroupTitleHeight = (group: V1EntitiesAnalyticsSurveysPagesPageGroup) => {
        return this.elementsHeight?.[this.getGroupTitleElId(group.id)] || 0;
      };
      // eslint-disable-next-line arrow-body-style
      const getQuestionHeight = (question: V1EntitiesAnalyticsSurveysPagesPageQuestion) => {
        return this.elementsHeight?.[this.getQuestionElId(question.id)] || 0;
      };

      // Итоговый массив
      const pages = [[]] as GroupsListItem[][];
      // Обрабатываемый список групп
      let groups = JSON.parse(JSON.stringify([...this.groupsList])) as GroupsListItem[];

      // Индекс заполняемой в данный момент страницы
      let currentPageIndex = 0;
      // Оставшаяся незаполненная высота на текущей странице
      let freeHeight = this.pageContentHeight;
      // Индекс первого вопроса из группы (для случая, когда часть вопросов переносится на следующую страницу)
      let groupStartNumber = 1;

      while (groups.length) {
        const currentGroup = groups[0];
        // Высота заголовка у списка (при переносе вопросов на след. страницу - заголовок повторно не выводится)
        const curGroupTitleHeight = currentGroup.group?.name && groupStartNumber === 1
          ? getGroupTitleHeight(currentGroup.group)
          : 0;

        // Если не влезает хотя бы одна строка из списка, создаем новую страницу
        if (curGroupTitleHeight + getQuestionHeight(currentGroup.questions[0]) > freeHeight) {
          currentPageIndex += 1;
          pages.push([]);
          freeHeight = this.pageContentHeight;
          // eslint-disable-next-line no-continue
          continue;
        }

        // Минимум одна строка списка уже поместится на страницу
        freeHeight -= (curGroupTitleHeight + getQuestionHeight(currentGroup.questions[0]) + TABLE_MB);
        const rowsToAdd = [currentGroup.questions.shift()!];

        // Добираем оставшиеся строки из списка, которые влезут на страницу
        while (currentGroup.questions.length) {
          const rowHeight = getQuestionHeight(currentGroup.questions[0]);
          if (rowHeight > freeHeight) {
            break;
          }
          rowsToAdd.push(currentGroup.questions.shift()!);
          freeHeight -= rowHeight;
        }

        // Добавляем список на страницу с частью строк, которые влезают
        pages[currentPageIndex].push({
          ...currentGroup,
          questions: rowsToAdd,
          startNumber: groupStartNumber,
        });

        groupStartNumber += rowsToAdd.length;

        // Если распределили все строки из списка, переходим к следующему
        if (!currentGroup.questions.length) {
          groups = groups.slice(1);
          groupStartNumber = 1;
        }
      }

      return pages;
    },
  },

  watch: {
    pages: {
      immediate: true,
      async handler() {
        // Рендерер готов тогда, когда список страниц рассчитался с учетом высоты элементов
        if ((this.pageContentHeight && Object.values(this.elementsHeight).length) || !this.questions.length) {
          await this.$nextTick();
          this.collectAndEmitImages();
        }
      },
    },
  },

  async mounted() {
    this.pageContentHeight = this.$el.querySelector('.page-content')?.getBoundingClientRect().height || 0;
    if (!this.pageContentHeight) {
      this.$emit('error');
    }

    this.calculateElementsHeight();
  },

  methods: {
    getGroupTitleElId(groupId: number) {
      return `group-title-${groupId}`;
    },
    getQuestionElId(questionId: number) {
      return `question-${questionId}`;
    },
    calculateElementsHeight() {
      this.elementsHeight = getElementsOuterHeights(this.$el as HTMLElement);
    },
  },
});
