
import { defineComponent, PropType } from 'vue';
import type { TranslateResult } from 'vue-i18n';
import PdfPage from '@/components/analytics/pdf/PdfPage.vue';
import PdfAbstractRenderer from '@/components/analytics/pdf/renderers/PdfAbstractRenderer.vue';
import { V1EntitiesAnalyticsFeedbackJohariQuestionsQuestion } from '@/services/api/tsxass';
import NoFilteredDataStub from '@/components/analytics/widgets/NoFilteredDataStub.vue';
import JohariZoneIcon from '@/components/analytics/pdf/widgets/johari/JohariZoneIcon.vue';
import GradeValueSmile from '@/components/analytics/charts/GradeValueSmile.vue';

interface QuestionsGroup {
  title?: string,
  rows: V1EntitiesAnalyticsFeedbackJohariQuestionsQuestion[],
}

const TABLE_TITLE_HEIGHT = 24;
const TABLE_HEADER_HEIGHT = 22;
const TABLE_ROW_HEIGHT = 42;
const TABLES_GAP = 16;

export default defineComponent({
  name: 'PdfJohariZoneRenderer',

  components: {
    PdfPage,
    JohariZoneIcon,
    GradeValueSmile,
    NoFilteredDataStub,
  },

  extends: PdfAbstractRenderer,

  props: {
    surveyeeName: {
      type: String,
      default: '',
    },
    pageNumber: {
      type: Number,
      default: 0,
    },
    zoneTitle: {
      type: String as PropType<TranslateResult | string>,
      default: '',
    },
    quadrantNumber: {
      type: Number,
      required: true,
    },
    description: {
      type: String as PropType<TranslateResult | string>,
      default: '',
    },
    questions: {
      type: Array as PropType<V1EntitiesAnalyticsFeedbackJohariQuestionsQuestion[]>,
      default: () => ([]),
    },
    minRating: {
      type: Number,
      default: 0,
    },
    maxRating: {
      type: Number,
      default: 5,
    },
    belowAnonymityThreshold: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      // Высота зоны страницы, в которой будут выводиться таблицы
      pageContentHeight: 0,
    };
  },

  computed: {
    cssVars(): Record<string, any> {
      return {
        '--table-title-height': `${TABLE_TITLE_HEIGHT}px`,
        '--table-header-height': `${TABLE_HEADER_HEIGHT}px`,
        '--table-row-height': `${TABLE_ROW_HEIGHT}px`,
        '--tables-gap': `${TABLES_GAP}px`,
      };
    },
    // Вопросы, сгруппированные в таблицы
    tables(): QuestionsGroup[] {
      const questionGroupsMap: Record<string, V1EntitiesAnalyticsFeedbackJohariQuestionsQuestion[]> = {};
      const questionsWithoutGroup: V1EntitiesAnalyticsFeedbackJohariQuestionsQuestion[] = [];

      this.questions.forEach((item) => {
        const key = item.groupQuestionName || '';
        if (!key) {
          questionsWithoutGroup.push(item);
        } else {
          if (!questionGroupsMap[key]) {
            questionGroupsMap[key] = [];
          }
          questionGroupsMap[key].push(item);
        }
      });

      const groups = Object.entries(questionGroupsMap).map(([title, rows]) => ({
        title,
        rows,
      }));

      return [
        {
          title: '',
          rows: questionsWithoutGroup,
        },
        ...groups,
      ].filter((table) => !!table.rows.length);
    },
    // Таблицы, распределенные по страницам
    pages(): QuestionsGroup[][] {
      if (!this.tables.length || !this.pageContentHeight) {
        return [[]];
      }

      // Итоговый массив
      const pages: QuestionsGroup[][] = [[]];
      // Обрабатываемый список таблиц
      let tables = [...this.tables];

      // Индекс заполняемой в данный момент страницы
      let currentPageIndex = 0;
      // Оставшаяся незаполненная высота на текущей странице
      let freeHeight = this.pageContentHeight;

      while (tables.length) {
        const currentTable = tables[0];
        const curTableTitleHeight = currentTable.title ? TABLE_TITLE_HEIGHT : 0;
        const curTableGap = pages[currentPageIndex].length ? TABLES_GAP : 0;
        // Сколько строк таблицы поместится в оставшемся пространстве на странице
        const rowsFitCount = Math.floor(
          (freeHeight - curTableTitleHeight - TABLE_HEADER_HEIGHT - curTableGap) / TABLE_ROW_HEIGHT,
        );

        if (rowsFitCount < 1) {
          currentPageIndex += 1;
          pages.push([]);
          freeHeight = this.pageContentHeight;
          // eslint-disable-next-line no-continue
          continue;
        }

        // Строки текущей таблицы, которые поместятся на странице
        const rowsToAdd = currentTable.rows.slice(0, rowsFitCount);
        // Добавляем таблицу на страницу с частью строк, которые влезают
        pages[currentPageIndex].push({
          ...currentTable,
          rows: rowsToAdd,
        });
        // Из текущей таблицы удаляем эти строки
        currentTable.rows = currentTable.rows.slice(rowsFitCount);
        // Добавили таблицу => свободное место на странице уменьшилось
        freeHeight -= rowsToAdd.length * TABLE_ROW_HEIGHT + curTableTitleHeight + TABLE_HEADER_HEIGHT + curTableGap;
        // Если распределили все строки из таблицы, переходим к следующей
        if (!currentTable.rows.length) {
          tables = tables.slice(1);
        }
      }

      return pages;
    },
  },

  watch: {
    pages: {
      async handler(newValue) {
        if (newValue.length && this.pageContentHeight) {
          await this.$nextTick();
          // Компонент готов тогда, когда список страниц рассчитан с учетом высоты контента и эти страницы отрисованы
          this.$emit('ready', { pagesCount: newValue.length });
        }
      },
      immediate: true,
    },
  },

  async mounted() {
    this.pageContentHeight = this.$el.querySelector('.tables')?.getBoundingClientRect().height || 0;
    if (!this.pageContentHeight) {
      this.$emit('error');
    }
  },
});
