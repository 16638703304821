
import { defineComponent } from 'vue';
import PdfPage from '@/components/analytics/pdf/PdfPage.vue';
import PdfAbstractRenderer from '@/components/analytics/pdf/renderers/PdfAbstractRenderer.vue';
import {
  V1EntitiesAnalyticsFeedbackExtraQuestionsTempGroup,
  V1EntitiesAnalyticsFeedbackGeneralQuestionsTempItem,
} from '@/services/api/tsxass';
import PdfHeatMapWidget from '@/components/analytics/pdf/widgets/heatMap/PdfHeatMapWidget.vue';
import { HeatMapColumn, HeatMapRow as HeatMapRowType } from '@/components/analytics/charts/HeatMap/types';
import i18n from '@/plugins/vue-i18n';

const TABLE_HEADER_HEIGHT = 85;
const ROWS_GROUP_PADDING = 10;
const ROWS_GROUP_HEADER_HEIGHT = 36;
const ROW_HEIGHT = 32;
const COLOR_CELL_COLUMNS_ON_PAGE_COUNT = 6;

const getGroupColumnName = (groupId: number) => `group-${groupId}`;

export default defineComponent({
  name: 'PdfHeatMapRenderer',

  components: {
    PdfPage,
    PdfHeatMapWidget,
  },

  extends: PdfAbstractRenderer,

  props: {
    surveyeeName: {
      type: String,
      default: '',
    },
    pageNumber: {
      type: Number,
      default: 0,
    },
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      // Высота зоны контента страницы
      pageContentHeight: 0,
      chartData: {
        groups: [] as V1EntitiesAnalyticsFeedbackExtraQuestionsTempGroup[],
        items: [] as V1EntitiesAnalyticsFeedbackGeneralQuestionsTempItem[],
        settings: {
          minValue: 1,
          maxValue: 5,
          displayBenches: false,
        },
      },
      dataIsLoaded: false,
      readyWidgetsCount: 0,
    };
  },

  computed: {
    fixedColumns(): HeatMapColumn[] {
      // определяет колонки таблицы тепловой карты
      console.log('override the computed value');
      return [];
    },
    // Сгруппированные строки таблицы
    rows(): HeatMapRowType[] {
      const preparedRows = this.chartData.items.map((item) => ({
        ...item,
        ...Object.fromEntries(
          item.values.map(({ groupId, value, options }) => [
            getGroupColumnName(groupId),
            this.getGroupColumnValue(value, options),
          ]),
        ),
      }));

      const itemsMap: Record<string | number, HeatMapRowType> = Object.fromEntries(
        preparedRows.map((item) => [item.id, { ...item, children: [] }]),
      );
      const rootItems: HeatMapRowType[] = [];

      Object.values(itemsMap).forEach((item) => {
        if (item.parentId) {
          itemsMap[item.parentId].children!.push(item);
        } else {
          rootItems.push(item);
        }
      });

      rootItems.sort((a, b) => a.sortIndex - b.sortIndex);
      rootItems.forEach((rootItem) => rootItem.children?.sort((a, b) => a.sortIndex - b.sortIndex));
      return rootItems;
    },
    // Столбцы, распределенные по страницам (не включают в себя фиксированные столбцы)
    columnsPages(): HeatMapColumn[][] {
      const cols = this.chartData.groups.map((group) => ({
        key: getGroupColumnName(group.id),
        title: group.name,
        isFixed: false,
      }));

      const res = [];
      while (cols.length > 0) {
        res.push(cols.splice(0, COLOR_CELL_COLUMNS_ON_PAGE_COUNT));
      }

      return res.length ? res : [[]];
    },
    // Список таблиц, распределенных по страницам
    tables() {
      // Итоговый массив
      const tables = [[]] as HeatMapRowType[][];
      // Обрабатываемый список
      const groups = JSON.parse(JSON.stringify([...this.rows]));
      // Индекс заполняемой в данный момент таблицы
      let currentTableIndex = 0;
      // Оставшаяся незаполненная высота на текущей странице
      let freeHeight = this.pageContentHeight - TABLE_HEADER_HEIGHT;

      while (groups.length) {
        const currentGroup = groups[0];
        // Сколько строк таблицы поместится в оставшемся пространстве на странице
        const rowsFitCount = Math.floor(
          (freeHeight - ROWS_GROUP_PADDING - ROWS_GROUP_HEADER_HEIGHT) / ROW_HEIGHT,
        );

        if (rowsFitCount < 2) {
          currentTableIndex += 1;
          tables.push([]);
          freeHeight = this.pageContentHeight - TABLE_HEADER_HEIGHT;
          // eslint-disable-next-line no-continue
          continue;
        }

        // Строки текущей группы, которые поместятся на странице
        const rowsToAdd = (currentGroup.children || []).slice(0, rowsFitCount);
        // Добавляем группу на страницу с частью строк, которые влезают
        tables[currentTableIndex].push({
          ...currentGroup,
          children: rowsToAdd,
        });
        // Из текущей группы удаляем эти строки
        currentGroup.children = (currentGroup.children || []).slice(rowsFitCount);
        // Добавили таблицу => свободное место на странице уменьшилось
        freeHeight -= rowsToAdd.length * ROW_HEIGHT + ROWS_GROUP_PADDING + ROWS_GROUP_HEADER_HEIGHT;
        // Если распределили все строки из группы, переходим к следующей
        if (!currentGroup.children.length) {
          groups.shift();
        }
      }

      return tables;
    },
    pageTitle() {
      return '';
    },
  },

  watch: {
    chartData(val) {
      // Пропускаем рендер, если данных нет
      if (val.groups.length === 0 && val.items.length === 0) {
        this.skipCurrentRenderer();
      }
    },
  },

  async mounted() {
    this.pageContentHeight = this.$el.querySelector('.page-content')?.getBoundingClientRect().height || 0;
    if (!this.pageContentHeight) {
      this.$emit('error');
    }

    this.loadChartData();
  },

  methods: {
    async loadChartData() {
      // загружает данные тепловой карты, выставляет значения для chartData и dataIsLoaded
      console.log('override the method');
    },
    async onWidgetReady() {
      if (!this.pageContentHeight || !this.dataIsLoaded) {
        return;
      }

      this.readyWidgetsCount += 1;
      if (this.tables.length * this.columnsPages.length === this.readyWidgetsCount) {
        this.collectAndEmitImages();
      }
    },
    getGroupColumnValue(value, options) {
      if (options && !options.display) {
        return i18n.t('analytics.baseCompetencies.heatMap.anonymityThresholdReached');
      }
      if (typeof value === 'number') {
        return this.chartData.settings.minValue === -100 ? Number(value.toFixed(0)) : Number(value.toFixed(0));
      }
      return null;
    },
  },
});
