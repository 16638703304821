
import { defineComponent } from 'vue';
import PdfPage from '@/components/analytics/pdf/PdfPage.vue';
import PdfAbstractRenderer from '@/components/analytics/pdf/renderers/PdfAbstractRenderer.vue';
import TopResultsWidget from '@/components/analytics/widgets/TopResultsWidget.vue';
import AverageRatingWidget from '../widgets/general/AverageRatingWidget.vue';

export default defineComponent({
  name: 'PdfGeneralIndicatorsRenderer',

  components: {
    PdfPage,
    AverageRatingWidget,
    TopResultsWidget,
  },

  extends: PdfAbstractRenderer,

  props: {
    surveyeeName: {
      type: String,
      default: '',
    },
    pageNumber: {
      type: Number,
      default: 0,
    },
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      successRenders: 0,
      skip: false,
    };
  },

  methods: {
    async onWidgetReady(e: { empty: boolean }) {
      if (this.skip) return;
      this.successRenders += 1;

      // Если хотя бы один рендерер пустой, пропускаем все
      if (e.empty) {
        this.skipCurrentRenderer();
        this.skip = true;
        return;
      }

      if (this.successRenders === 2) {
        await this.collectAndEmitImages();
      }
    },
    onWidgetError() {
      this.$emit('error');
    },
  },
});
