
// @ts-ignore
// eslint-disable-next-line
import domtoimage from 'dom-to-image-more';
import PdfPage from '@/components/analytics/pdf/PdfPage.vue';
import timeoutPromise from '@/helpers/promisify';

const DEFAULT_JPEG_QUALITY = 0.5; // качество выше 0.75 сильно увеличивает вес изображения
const IMAGE_SCALE_FACTOR = 2; // коэффициент выше 4 сильно увеличивает вес изображения

export default {
  name: 'PdfAbstractRenderer',

  components: { PdfPage },

  props: {
    pageNumber: {
      type: Number,
      default: 0,
    },
    renderStartPageNumber: {
      type: Number,
      default: 0,
    },
    renderPagesCount: {
      type: Number,
      default: 0,
    },
    domToImage: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    paramJpegQuality(): number {
      return Number(this.$route.query.jpegQuality) || DEFAULT_JPEG_QUALITY;
    },
    paramImageScale(): number {
      return Number(this.$route.query.imageScale) || IMAGE_SCALE_FACTOR;
    },
  },

  methods: {
    async createImagePromises(pages: HTMLElement[]): Promise<Promise<string | null>[]> {
      const imagePromises = pages.map(async (page: HTMLElement, idx: number): Promise<string | null> => {
        const pageIdx = this.pageNumber + idx;
        const pageSkipIsPossible = this.renderPagesCount > 0;
        const pageIsBeforeRender = pageIdx < this.renderStartPageNumber;
        const pageIsAfterRender = pageIdx > this.renderStartPageNumber + this.renderPagesCount - 1;
        if (pageSkipIsPossible && (pageIsBeforeRender || pageIsAfterRender)) {
          return null;
        }
        try {
          if (this.domToImage) {
            // тут качество картинки выставляется, можно понизить до 2 или до 3.
            const scale = this.paramImageScale;
            const dataUrl = await domtoimage.toPng(page as HTMLElement, {
              width: page.clientWidth * scale,
              height: page.clientHeight * scale,
              quality: this.paramJpegQuality,
              style: {
                transform: `scale(${scale})`,
                transformOrigin: 'top left',
                width: `${page.offsetWidth}px`,
                height: `${page.offsetHeight}px`,
              },
            });
            return dataUrl;
          }
          return '';
        } catch (err) {
          this.$emit('error', err);
          return null;
        }
      });
      return imagePromises;
    },
    async collectAndEmitImages() {
      const pages = [...this.$el.querySelectorAll('.pdf-page')] as HTMLElement[];
      if (pages.length) {
        // скроллим до последней страницы
        pages[pages.length - 1].scrollIntoView();
        // ждём, чтобы всё догрузилось что не догружено
        await timeoutPromise(500);
        // генерим картинки по количеству страниц
        const images = await Promise.all(await this.createImagePromises(pages));
        // скроллим обратно наверх
        pages[0].scrollIntoView();
        this.$emit('success', images);
      } else {
        // вообще, возможна такая ситуация, когда страниц нет, скриншоты не нужны,
        // нужно просто перейти к следующему чанку
        this.$emit('success');
        // this.$emit('error', new Error('No pages generated'));
      }
    },
    skipCurrentRenderer() {
      this.$emit('success', []);
    },
  },
};
